import Lil from '@loginline/core';
import { useState, useRef } from 'react';
import { shallowCompare } from '../utils';
import { useDeepCompareEffect } from 'react-use';

const useFillStore = (route, params, options, mutate, onError) => {
  const [store, update] = Lil.useStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const paramsRef = useRef(params);
  if (!shallowCompare(params, paramsRef.current)) {
    paramsRef.current = params;
  }

  useDeepCompareEffect(() => {
    if (options.skip) return;
    if (options.fetchPolicy === 'cache-first' && store[options.cacheKey]) return;
    setLoading(true);
    if (store.userLoggedIn) {
      Lil.method(route, params, result => {
        if (result.error) {
          onError && onError(result.error);
          setError(result.error);
          setLoading(false);
        } else {
          mutate && update(mutate(store, result));
          setLoading(false);
          setError(null);
        }
      });
    }
  }, [route, params, store.userLoggedIn]);

  return [store, loading, error];
};

export default useFillStore;