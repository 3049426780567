import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer,TableHead,TableRow, Typography, Paper } from '@material-ui/core';

// Alternance de couleur des rangées
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.light.main,
        },
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    tableRoot: {
        borderRadius: 10,
        boxShadow: "none" // Suppression bordure 
    }
}));


const TableA7StocksDetails = (props) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} className={classes.tableRoot}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <StyledTableRow>
                        {props.titles2.map((title, i) => (
                            <StyledTableCell key={i}>{title}</StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>

                <TableBody>
                    {props.rows.map((row, i) => (
                        <React.Fragment key={`row-${i}`}>
                            <StyledTableRow key={i}>
                                {row.map((column, i) => (
                                    <StyledTableCell key={i}>{column}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "1.5rem",
        marginBottom: 20
    },
    body: {
        fontSize: 13,
        borderBottom: "none",
        textAlign: "center",
        '&:nth-last-of-type(1)': {
            //borderRadius: "0px 10px 10px 0px",
        },
        '&:first-of-type': {
            paddingTop: 20,
            fontWeight: "bold",
            fontSize: 11
        }
    },
    root: {
        borderBottom: "none",
    },
}))(TableCell);

export default TableA7StocksDetails;