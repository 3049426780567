/**
 * fn to enrich a dict with a list by id
 * @param {{[id: string]: any}} base The base object
 * @param {{id: string, [key: string]: any}} enrich The list with the entries to enrich the base object with
 * @param {String} id An optional key to use instead of id
 * @returns The base enriched with the list
 */
export const enrichDictWithList = (base, enrich, id = "id") => ({
  ...(base || {}),
  ...enrich.reduce(
    (acc, entry) => ({
      ...acc,
      [entry[id]]: {
        ...((base && base[id]) || {}),
        ...entry,
      },
    }),
    {}
  ),
});

export const shallowCompare = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const formatDate = input => {
  const datePart = input ? input.match(/\d+/g) : null;
  if (datePart && datePart.length && datePart.length >= 3) {
    const year = datePart[0];
    const month = datePart[1];
    const day = datePart[2];
    return day + "/" + month + "/" + year;
  } else {
    return "...";
  }
};

// Mise en forme des données du tableau
export const formatPrice = number =>
  new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(number);

// On trie le tableau suivant le montant investi et on place toujours Liquidité en dernier
export const compare_investment = (a, b) => {
  if (a.display_name === "Liquidité") {
    return 1;
  }
  if (b.display_name === "Liquidité") {
    return -1;
  }
  if (a.montant_investi > b.montant_investi) {
    return -1;
  } else if (a.montant_investi < b.montant_investi) {
    return 1;
  } else {
    return 0;
  }
};

export const compareExpiryDate = (a, b) => {
  if (a.date_fin < b.date_fin) {
    return -1;
  } else if (a.date_fin > b.date_fin) {
    return 1;
  } else {
    return 0;
  }
};

export const compareEndDate = (a, b) => {
  if (new Date(a.end_date) < new Date(b.end_date)) {
    return -1;
  } else if (a.end_date > b.end_date) {
    return 1;
  } else {
    return 0;
  }
};